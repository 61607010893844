<template>
  <div>
    <Card>
      <Card>
        <div style="text-align: left">
          <Form :model="queryinfo" :label-width="80">
            <Row>
              <Col span="4" style="padding-left: 10px">
                <RadioGroup
                  type="button"
                  button-style="solid"
                  v-model="queryinfo.status"
                  @on-change="alertstatuschange"
                >
                  <Radio label="firing">未恢复</Radio>
                  <Radio label="resolved">已解决</Radio>
                </RadioGroup>
              </Col>
            </Row>
            <Row>
              <Col span="10" style="margin-top: 25px; padding-left: 12px">
                <CheckboxGroup
                  v-model="severitylist"
                  @on-change="alertseveritychange"
                  >等级
                  <Checkbox
                    label="P0"
                    style="margin-left: 10px; color: #e45959"
                  ></Checkbox>
                  <Checkbox label="P1" style="color: #e97659"></Checkbox>
                  <Checkbox label="P2" style="color: #ffa059"></Checkbox>
                  <Checkbox label="P3" style="color: #ffc859"></Checkbox>
                  <Checkbox label="P4" style="color: #7499ff"></Checkbox>
                  <Checkbox label="P5" style="color: #97aab3"></Checkbox>
                </CheckboxGroup>
              </Col>
            </Row>
            <Row>
              <Col span="4"
                ><br />
                <FormItem label="告警名称">
                  <Input
                    v-model="queryinfo.name"
                    placeholder="请输入事件名称"
                    clearable
                    @on-enter="alertstatuschange"
                    @on-change="alertstatuschange"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="4"
                ><br />
                <FormItem label="告警对象">
                  <Input
                    v-model="queryinfo.host_name"
                    placeholder="请输入主机"
                    clearable
                    @on-enter="alertstatuschange"
                    @on-change="alertstatuschange"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="4"
                ><br />
                <FormItem label="业务">
                  <Input
                    v-model="queryinfo.business"
                    placeholder="请输入业务名"
                    clearable
                    @on-enter="alertstatuschange"
                    @on-change="alertstatuschange"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="4"
                ><br />
                <FormItem label="告警源">
                  <Input
                    v-model="queryinfo.source"
                    placeholder="请输入告警源"
                    clearable
                    @on-enter="alertstatuschange"
                    @on-change="alertstatuschange"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="4"
                ><br />
                <FormItem label="集成">
                  <Input
                    v-model="queryinfo.integration"
                    placeholder="请输入集成"
                    clearable
                    @on-enter="alertstatuschange"
                    @on-change="alertstatuschange"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <div>
        <Table border :columns="columns" :data="data" size="small"></Table>
      </div>
      <br />
      <template>
        <Page
          :current="queryinfo.page_num"
          :total="total"
          :page-size-opts="pagesizeopts"
          @on-change="changepage"
          @on-page-size-change="changepagesize"
          show-total
          show-sizer
          style="text-align: right"
        />
      </template>
      <Drawer
        :closable="false"
        v-model="showdetails"
        title="告警详情"
        width="600"
      >
        <!--<h4>常规属性</h4>-->
        <div class="alert-detail">
          <div
            class="alert-detail-row"
            v-for="(v, k) of alertDetailFieldKeys"
            :key="k"
          >
            <div class="alert-detail-key">
              {{ v }}
            </div>
            <div class="alert-detail-val">
              <a
                target="_blank"
                :href="detailsinfo.fields['pp_object_address']"
                v-if="k === 'pp_object'"
                >{{ detailsinfo.fields[k] }}</a
              >
              <a
                target="_blank"
                :href="detailsinfo.fields['pp_graph_url']"
                v-else-if="k === 'pp_check_item'"
                >{{ detailsinfo.fields[k] }}</a
              >
              <a
                target="_blank"
                :href="detailsinfo.fields['pp_event_url']"
                v-else-if="k === 'pp_event_id'"
                >{{ detailsinfo.fields[k] }}</a
              >
              <span v-else-if="k === 'pp_alert_id'">{{ detailsinfo.id }}</span>
              <span v-else>{{ alertFieldValue(detailsinfo.fields, k) }}</span>
            </div>
          </div>
        </div>

        <h5 style="margin-top: 30px">扩展字段</h5>

        <div class="alert-detail" style="padding: 5px 5px 20px;">
          <div
            class="alert-detail-row"
            v-for="(pair, index) of detailsinfo.extends"
            :key="index"
          >
            <div class="alert-detail-key" style="text-align: left;">
              {{ pair["name"] }}
            </div>
            <div class="alert-detail-val">
              {{ pair["value"] }}
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        :closable="false"
        v-model="showNotifyLogs"
        title="通知历史"
        width="400"
      >
        <div
          v-for="item of notifyLogs"
          :key="item.time"
          style="margin-bottom: 15px"
        >
          <p style="font-size: 11px; color: #666">
            <span style="margin-right: 10px; color: #555"
              >策略: {{ item.route_name }}</span
            >
            {{ timestampToTime(item.time * 1000) }}
          </p>
          <p
            :style="{
              fontSize: '12px',
              color: item.resolved ? 'green' : 'inhirit',
            }"
          >
            通过
            <Tooltip :content="item.media_desc" placement="top-start">
              [{{
                item.media_type == "wechat_webhook"
                  ? "企业微信群"
                  : item.media_type
              }}]
            </Tooltip>
            通知了 [{{ item.receiver_name }}]
          </p>
        </div>
      </Drawer>
    </Card>
  </div>
</template>

<script>
import Notice from "@/components/baseNotice";
import { get, getAlertAPIUrl } from "@/api/http.js";
export default {
  name: "history",
  data() {
    return {
      data: [],
      showdetails: false,
      showNotifyLogs: false,
      notifyLogs: [],
      pagesizeopts: [20, 30, 50, 100],
      selectseverity: {
        P0: 0,
        P1: 1,
        P2: 2,
        P3: 3,
        P4: 4,
        P5: 5,
      },
      severitynum: {
        0: "P0",
        1: "P1",
        2: "P2",
        3: "P3",
        4: "P4",
        5: "P5",
      },
      total: 0,

      data_extends: [],
      columns_extends: [
        { title: "告警名字", key: "name" },
        { title: "告警值", key: "value" },
      ],
      columns: [
        // {type: 'selection', width: 60, align: 'center'},
        {
          title: "告警名称",
          key: "pp_alert_name",
          minWidth: 200,
          render: (h, { row }) => {
            return h("div", this.get_pp_alert_name(row.fields));
          },
        },
        {
          title: "告警对象",
          key: "pp_object",
          minWidth: 200,
          render: (h, { row }) => {
            return h("div", this.get_pp_object(row.fields));
          },
        },
        {
          title: "等级",
          key: "pp_severity",
          minWidth: 70,
          render: (h, { row }) => {
            return h("div", this.get_pp_severity(row.fields));
          },
        },
        // {title: '状态', key: 'pp_alert_status', minWidth: 80,
        //   render: (h,{row}) =>{
        //     return h('div',this.get_pp_alert_status(row.fields))
        //   }
        // },
        {
          title: "发生时间",
          key: "pp_start_time",
          minWidth: 150,
          render: (h, { row }) => {
            return h("div", this.get_pp_start_time(row.fields));
          },
        },
        {
          title: "更新时间",
          key: "pp_last_time",
          minWidth: 150,
          render: (h, { row }) => {
            return h("div", this.get_pp_last_time(row.fields));
          },
        },
        {
          title: "结束时间",
          key: "pp_end_time",
          minWidth: 100,
          render: (h, { row }) => {
            return h("div", this.get_pp_end_time(row.fields));
          },
        },

        // {title: '触发次数', key: 'pp_trigger_count',width: 100,
        //   render: (h,{row}) =>{
        //     return h('div',this.get_pp_trigger_count(row.fields))
        //   }
        // },

        {
          title: "业务",
          key: "pp_business",
          minWidth: 100,
          render: (h, { row }) => {
            return h("div", this.get_pp_business(row.fields));
          },
        },
        // {title: '告警源', key: 'pp_source',width: 150,
        //   render: (h,{row}) =>{
        //     return h('div',this.get_pp_source(row.fields))
        //   }
        // },
        // {title: '集成', key: 'pp_integration_name',width: 150,
        //   render: (h,{row}) =>{
        //     return h('div',this.get_pp_source(row.fields))
        //   }
        // },
        // {title: '样本值', key: 'pp_opvalue',width: 200,
        //   render: (h,{row}) =>{
        //     return h('div',this.get_pp_opvalue(row.fields))
        //   }
        // },
        {
          title: "操 作",
          key: "action",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "a",
                {
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.showAlertDetail(params.row);
                    },
                  },
                },
                "详情"
              ),

              h(
                "a",
                {
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.showNotifyLogList(params.row);
                    },
                  },
                },
                "通知"
              ),
            ]);
          },
        },
      ],
      queryinfo: {
        page_num: 1,
        page_size: 20,
        name: "",
        status: "firing",
        host: "",
        severity: "0,1",
        business: "",
        integration: "",
      },
      severitylist: ["P0", "P1"],
      detailsinfo: {
        id: "",
        fields: "",
        extends: "",
      },

      alertDetailFieldKeys: {
        pp_alert_name: "告警名称",
        pp_alert_id: "告警ID",
        pp_severity: "告警等级",
        pp_alert_status: "告警状态",
        pp_business: "业务",
        pp_event_id: "事件ID",
        pp_start_time: "开始时间",
        pp_end_time: "结束时间",
        pp_last_time: "触发时间",
        pp_trigger_count: "触发次数",
        pp_object: "告警对象",
        pp_check_item: "监控指标",
        pp_opvalue: "告警值",
        pp_source: "告警源",
        pp_integration_name: "集成",
      },
    };
  },
  methods: {
    alertFieldValue(fields, k) {
      switch (k) {
        case "pp_alert_status":
          return fields[k] === "firing" ? "未解决" : "已恢复";
        case "pp_severity":
          return "P" + fields[k];
        case "pp_start_time":
        case "pp_last_time":
          return this.time_str(fields[k] * 1000);
        case "pp_end_time":
          if (fields[k] > 0) {
            return this.time_str(fields[k] * 1000);
          } else {
            return "--";
          }
        default:
          return fields[k];
      }
    },
    get_pp_alert_name(fields) {
      return fields["pp_alert_name"];
    },
    get_pp_alert_status(fields) {
      return fields["pp_alert_status"] == "firing" ? "未恢复" : "已解决";
    },
    get_pp_business(fields) {
      return fields["pp_business"];
    },
    get_pp_source(fields) {
      return fields["pp_source"];
    },

    get_pp_object(fields) {
      return fields["pp_object"];
    },
    get_pp_trigger_count(fields) {
      return fields["pp_trigger_count"];
    },
    get_pp_severity(fields) {
      const severity = fields["pp_severity"];
      return this.severitynum[severity];
    },

    timestampToTime(cjsj) {
      var date = new Date(cjsj); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },

    time_str(timestamp) {
      return this.timestampToTime(timestamp);
    },

    get_pp_last_time(fields) {
      const pp_last_time = fields["pp_last_time"];
      return this.time_str(pp_last_time * 1000);
    },

    get_pp_end_time(fields) {
      const pp_end_time = fields["pp_end_time"];
      if (pp_end_time === "0") {
        return "--";
      } else {
        return this.time_str(pp_end_time * 1000);
      }
    },

    get_pp_start_time(fields) {
      const pp_start_time = fields["pp_start_time"];
      return this.time_str(pp_start_time * 1000);
    },

    changepage(page) {
      this.queryinfo.page_num = page;
      this.gethistoryalert(this.queryinfo);
    },

    changepagesize(pagesize) {
      this.queryinfo.page_size = pagesize;
      this.gethistoryalert(this.queryinfo);
    },

    alertstatuschange() {
      this.queryinfo.name = this.queryinfo.name.trim();
      this.queryinfo.business = this.queryinfo.business.trim();
      this.queryinfo.integration = this.queryinfo.integration.trim();
      this.gethistoryalert(this.queryinfo);
    },
    alertseveritychange(valuelist) {
      let severity = "";
      for (let i in valuelist) {
        severity = severity + this.selectseverity[valuelist[i]] + ",";
      }
      this.queryinfo.severity = severity.substring(0, severity.length - 1);
      this.gethistoryalert(this.queryinfo);
    },

    showAlertDetail(row) {
      this.detailsinfo = row;
      this.data_extends = row.extends;
      this.showdetails = true;
    },

    showNotifyLogList(row) {
      //console.log("row is:",row);
      this.notifyLogs = [];
      this.getNotifyLogs(row.id);
      this.showNotifyLogs = true;
    },

    getNotifyLogs(id) {
      const url = getAlertAPIUrl(`/api/v1/nflog/${id}`);
      get(url).then((resp) => {
        if (resp["code"] === 200) {
          this.notifyLogs = resp["data"] || [];
        } else {
          Notice.error("请求失败，请稍后再试");
        }
      });
    },

    gethistoryalert(queryinfo) {
      const url = getAlertAPIUrl("/api/v1/alerts");
      get(url, queryinfo).then((resp) => {
        if (resp["code"] === 200) {
          this.total = resp["data"]["total"];
          if (!resp["data"]["items"]) {
            this.data = [];
          } else {
            //console.log(resp["data"]["items"][0]);
            this.data = resp["data"]["items"];
          }
        } else {
          Notice.error("请求失败，请稍后再试");
        }
      });
    },
  },
  created() {
    this.gethistoryalert(this.queryinfo);
  },
};
</script>

<style scoped>
.alert-detail {
  display: table;
}

.alert-detail-row {
  display: table-row;
  font-size: 12px;
}

.alert-detail-key,
.alert-detail-val {
  display: table-cell;
  padding: 5px 10px;
}

.alert-detail-key {
  text-align: right;
}
</style>
